/* eslint-disable camelcase */
import React, { useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'adapter/next/router';
import validator from 'email-validator';
import {
  FORM_TRACKING_PROVIDERS,
  TRACKING_EVENT_NAMES,
  baseTrackingFieldsGenerator,
  useAnalytics
} from '@konsus/analytics';
import { type BoxProps } from '@konsus/superside-kit';
import GlobalContext from '../../contexts/GlobalContext';
import { prepareHubspotFormData, useEventId } from '../../utils/form';
import { getHubspotCommonFields } from '../../blocks/BookCall/hubspotFields';
import { hubSpotApiPoint, portalId } from '../../blocks/BookCall/utils';
import { EmailCapturingFormContent } from './EmailCapturingFormContent';

const isSSR = typeof window === 'undefined';

const validate = (values) => {
  const errors = { email: undefined } as { email: string | undefined };

  if (!values.email || !validator.validate(values.email)) {
    errors.email = 'Please provide your email';
  }

  return errors;
};

const EmailCapturingForm: React.FC<
  BoxProps & {
    redirectLink?: string;
    placeholder?: string;
    eventText?: string;
    buttonLabel?: string;
    newWindow?: boolean;
    formId?: string;
    onSuccess?: () => void;
    trackingEvents?: {
      form: string;
      form_type: string;
      item: string;
    };
  }
> = (props) => {
  const {
    redirectLink = '/get-started',
    newWindow = false,
    formId = '7745a6c2-4671-4146-ab72-01525b0de032',
    buttonLabel = 'Get Started',
    placeholder = 'Your work email',
    onSuccess,
    FormComponent = EmailCapturingFormContent,
    trackingEvents,
    ...rest
  } = props;

  const { utm, gclid } = useContext(GlobalContext) || {};

  const { track } = useAnalytics();
  const eventId = useEventId();

  const history = useRouter();

  useEffect(() => {
    setTimeout(() => {
      history.prefetch(redirectLink);
    }, 500);
  });

  const redirect = useCallback(
    (form) => {
      const email = encodeURIComponent(form.email);

      const finalRedirectLink = redirectLink.includes('calendly')
        ? `${redirectLink}${redirectLink.includes('?') ? '&' : '?'}email=${email}`
        : `${redirectLink}?email=${email}`;

      if (!isSSR) {
        if (newWindow) {
          window.open(finalRedirectLink, '_blank');

          return;
        }

        if (finalRedirectLink.includes('https://www.superside.com')) {
          const path = finalRedirectLink.split('https://www.superside.com');

          if (path.length > 1) {
            history.push(path[1]);

            return;
          } else {
            location.assign(finalRedirectLink);

            return;
          }
        }

        if (!finalRedirectLink.includes('http')) {
          history.push(finalRedirectLink);

          return;
        }

        location.assign(finalRedirectLink);
      }
    },
    [redirectLink, newWindow, history]
  );

  const hubSpotHandle = useCallback(
    (form) => {
      const { utm_source, utm_medium, utm_campaign } = utm || {};

      let fields = getHubspotCommonFields({ form, utm_campaign, utm_source, utm_medium, gclid });

      fields = fields.filter((field) => field?.value);

      const baseTrackingFields = baseTrackingFieldsGenerator(
        eventId,
        formId,
        trackingEvents,
        history?.asPath
      );

      const data = prepareHubspotFormData(fields);

      return fetch(`${hubSpotApiPoint}/${portalId}/${formId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(() => {
          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION, {
            ...baseTrackingFields,
            email: form.email,
            provider: FORM_TRACKING_PROVIDERS.INTERNAL_INLINE
          });

          if (onSuccess) {
            onSuccess();
          }
          redirect(form);
        })
        .catch((err) => {
          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION_FAILURE, {
            ...baseTrackingFields,
            email: form.email,
            error: err.message,
            provider: FORM_TRACKING_PROVIDERS.INTERNAL_INLINE
          });

          redirect(form);
        });
    },
    [utm, gclid, eventId, formId, trackingEvents, onSuccess, redirect, track, history]
  );

  return (
    <FormComponent
      onSubmit={hubSpotHandle}
      validate={validate}
      buttonLabel={buttonLabel}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default EmailCapturingForm;
