import React, { type ChangeEvent, forwardRef, useState } from 'react';
import { Button, FormField } from '@konsus/superside-kit';
import { TextInput } from '@superside/ui';

export interface EmailCapturingFormContentProps {
  buttonLabel: string;
  placeholder?: string;
  onSubmit: (form: any) => Promise<any>;
  validate: (values: any) => any;
}

export const EmailCapturingFormContent = forwardRef<
  HTMLInputElement,
  EmailCapturingFormContentProps
>((props, ref) => {
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const errors: any = props.validate({ email });

    if (errors.email) {
      setError(errors.email);

      return;
    }
    setLoading(true);
    props.onSubmit({ email }).then(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='480:flex-row flex max-w-full flex-col gap-2'>
        <FormField className='mb-0 flex-1' invalid={Boolean(error)} error={error}>
          <TextInput
            ref={ref}
            type='email'
            placeholder={props.placeholder}
            onChange={inputOnChange}
          />
        </FormField>
        <div>
          <Button
            fill='horizontal'
            primary
            bold
            type='submit'
            loading={loading}
            disabled={loading}
            label={props.buttonLabel}
          />
        </div>
      </div>
    </form>
  );
});

EmailCapturingFormContent.displayName = 'EmailCapturingFormContent';
