import React from 'react';
import { cn } from '@superside/ui';
import { BreakpointBasisBox, type BreakpointBasisBoxProps } from '../BreakpointBasisBox';
import { RelativeBox } from '../Box';
import { Text } from '../Text';
import { Invalid } from './Invalid';
import { Label } from './Label';

export interface FormFieldProps extends BreakpointBasisBoxProps {
  label?: string;
  error?: string;
  invalid?: boolean;
  optional?: boolean;
  description?: string;
  htmlFor?: string;
  Icon?: React.FC<Partial<HTMLElement>>;
  children?: React.ReactNode;
}

export const FormField: React.FC<FormFieldProps> = (props) => {
  const {
    label,
    error,
    invalid = Boolean(error),
    optional,
    description,
    htmlFor,
    Icon,
    ...rest
  } = props;

  return (
    <BreakpointBasisBox align='stretch' margin={{ bottom: '5' }} className='basis-full' {...rest}>
      {label ? (
        <Label margin={{ bottom: '2' }} invalid={invalid} htmlFor={htmlFor}>
          {label}
          {optional ? ' (optional)' : ''}
        </Label>
      ) : null}
      <RelativeBox
        className={cn(Icon && '[&_input]:pl-12')}
        background={{ color: 'transparent', dark: false }}
      >
        {Icon && <Icon className='absolute left-4 top-2/4 -translate-y-2/4' />}
        {props.children}
      </RelativeBox>
      {description ? (
        <Text margin={{ top: '4', bottom: 'xsmall' }} size='small' color='#FAFAFA'>
          {description}
        </Text>
      ) : null}
      {invalid && error ? <Invalid>{error}</Invalid> : null}
    </BreakpointBasisBox>
  );
};

export default FormField;
