import type React from 'react';
import { type BreakpointBasisBoxProps } from './BreakpointBasisBoxProps';
import styles from './breakpointBasisStyles.module.css';

const tokensBySize: any = {
  xxlg: {
    name: '--breakpoint-xxlg',
    val: 'var(--breakpoint-xxlg)'
  },
  xlg: {
    name: '--breakpoint-xlg',
    val: 'var(--breakpoint-xlg)'
  },
  lg: {
    name: '--breakpoint-lg',
    val: 'var(--breakpoint-lg)'
  },
  md: {
    name: '--breakpoint-md',
    val: 'var(--breakpoint-md)'
  },
  sm: {
    name: '--breakpoint-sm',
    val: 'var(--breakpoint-sm)'
  },
  xsm: {
    name: '--breakpoint-xsm',
    val: 'var(--breakpoint-xsm)'
  }
};

const fullPadStyles: any = {
  xxlg: 'px-0',
  lg: styles.fullPadLg,
  md: styles.fullPadMd,
  sm: styles.fullPadSm
};

const sizeProps = ['xxlg', 'xlg', 'lg', 'md', 'sm', 'xsm'];

function getFlexBasis(props: React.PropsWithChildren<BreakpointBasisBoxProps>, value: any) {
  return typeof value !== 'undefined'
    ? typeof value === 'string'
      ? value
      : (value / 12) * 100 + '%'
    : null;
}

export const useFlexBasisProps = (props: any) => {
  const className = [];
  const style: any = {};
  let addClass = false;

  sizeProps.forEach((prop) => {
    const value = props[prop];

    if (typeof value !== 'undefined') {
      addClass = true;
      style[tokensBySize[prop].name] = getFlexBasis(props, value);

      if (value === 12 && !props.useFullPad) {
        className.push(fullPadStyles[prop]);
      }
    }
  });

  if (addClass) {
    className.push(styles.breakpointBasisStyles);
  }

  return {
    className,
    style: {
      ...style
    }
  };
};
