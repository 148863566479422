/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'adapter/next/router';
import { prepareHubspotFormData } from '../../utils/form';
import { generateExtraFields } from './bookCallUtils';

export function useHubspotFormDataSanitizer({
  eventId,
  email,
  removePhone,
  removeCompany,
  removeCompanySize,
  removeDepartment,
  removeIntentQuestion,
  addSubscribeCheckbox,
  addInterestedInDemoCheckbox,
  addAbmRoundtableAttendanceField,
  addPreferredSessionField,
  addWhatDescribesYouField,
  addJobTitleField,
  utm_source,
  utm_medium,
  utm_campaign,
  gclid
}) {
  const history = useRouter();
  const [extraFields, setExtraFields] = useState<{ name: string; value: any }[]>([]);

  useEffect(() => {
    generateExtraFields(eventId, history.query.fbclid).then(setExtraFields);
  }, [eventId, history.query.fbclid]);

  return useCallback(
    (form) => {
      const commonFields = getHubspotCommonFields({
        form,
        utm_campaign,
        utm_source,
        utm_medium,
        gclid,
        ...history.query
      });

      let fields = [...commonFields, ...extraFields];

      if (email) {
        fields = fields.filter((item) => item?.name !== 'email');
        fields.push({
          name: 'email',
          value: email
        });
      }

      if (!removePhone) {
        fields.push({
          name: 'phone',
          value: form.mobile
        });
      }

      if (!removeCompany) {
        fields.push({
          name: 'company',
          value: form.company
        });
      }

      if (!removeCompanySize) {
        fields.push({
          name: 'company_size',
          value: form.employees
        });
      }

      if (!removeDepartment) {
        fields.push({
          name: 'industry',
          value: form['00N1t00000LkvSr']
        });
      }

      if (!removeIntentQuestion) {
        fields.push({
          name: 'intent',
          value: form.intent
        });
      }

      if (addSubscribeCheckbox) {
        fields.push({
          name: 'subscription_checkbox_to_blog',
          value: form.subscribe
        });
      }

      if (addInterestedInDemoCheckbox) {
        fields.push({
          name: 'interested_in_demo',
          value: form.interested_in_demo
        });
      }

      if (addAbmRoundtableAttendanceField) {
        fields.push({
          name: 'abm_roundtable_attendance',
          value: form.abm_roundtable_attendance
        });
      }

      if (addPreferredSessionField) {
        fields.push({
          name: 'preferred_roundtable_session__creative',
          value: form.preferred_roundtable_session__creative
        });
      }

      if (addWhatDescribesYouField) {
        fields.push({
          name: 'persona_bucket',
          value: form.persona_bucket
        });
      }

      if (addJobTitleField) {
        fields.push({
          name: 'jobtitle',
          value: form.jobtitle
        });
      }

      fields.push({
        name: 'form_name',
        value: form.form_name
      });

      fields = fields.filter((field) => field?.value);

      const formData = prepareHubspotFormData(fields);

      return {
        formData,
        utm_source,
        utm_medium,
        utm_campaign
      };
    },
    [
      utm_campaign,
      utm_source,
      utm_medium,
      gclid,
      history.query,
      extraFields,
      email,
      removePhone,
      removeCompany,
      removeCompanySize,
      removeDepartment,
      removeIntentQuestion,
      addSubscribeCheckbox,
      addInterestedInDemoCheckbox,
      addAbmRoundtableAttendanceField,
      addPreferredSessionField,
      addWhatDescribesYouField,
      addJobTitleField
    ]
  );
}

export const getHubspotCommonFields = ({
  form,
  utm_campaign,
  utm_source,
  utm_medium,
  gclid,
  content_referred_by = null
}) => {
  return [
    {
      name: 'firstname',
      value: form.first_name
    },
    {
      name: 'lastname',
      value: form.last_name
    },
    {
      name: 'email',
      value: form.email
    },
    {
      name: 'utm_campaign__c',
      value: utm_campaign
    },
    {
      name: 'utm_source__c',
      value: utm_source
    },
    {
      name: 'utm_medium__c',
      value: utm_medium
    },
    {
      name: 'gclid',
      value: gclid
    },
    content_referred_by
      ? {
          name: 'content_referred_by',
          value: content_referred_by
        }
      : null
  ].filter(Boolean) as { name: string; value: string }[];
};
