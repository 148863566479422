import React, { memo, useContext } from 'react';
import LazyHydrate from 'react-lazy-hydration';
import { Box, type BoxProps } from '@konsus/superside-kit';
import { LegoGlobalContext } from '..';

const getMargin = (props) => {
  return props.marginTop || props.marginLeft || props.marginBottom || props.marginRight
    ? {
        top: props.marginTop,
        left: props.marginLeft,
        bottom: props.marginBottom,
        right: props.marginRight
      }
    : undefined;
};

const getPadding = (props) => {
  return props.paddingTop || props.paddingLeft || props.paddingBottom || props.paddingRight
    ? {
        top: props.paddingTop,
        left: props.paddingLeft,
        bottom: props.paddingBottom,
        right: props.paddingRight
      }
    : undefined;
};

const ALWAYS_HYDRATED = 2;

const renderBlocks = memoize((items, source, blockByType, lazyHydrate = false) => {
  if (!items) {
    return null;
  }

  return items.filter(Boolean).map(({ _key, ...item }, i) => {
    const key = _key || `${item._type}_${i}`;
    const block = <Block key={key} position={i} {...item} blockByType={blockByType} />;

    return lazyHydrate && i >= ALWAYS_HYDRATED ? (
      <LazyHydrate key={key} whenVisible>
        {block}
      </LazyHydrate>
    ) : (
      block
    );
  });
});

const LegoBlock: React.FC<any> = (props) => {
  const { _type, blockByType, hasSessionUser, loggedInOnly, anonymousOnly, ...rest } = props;

  const BlockComponent = blockByType[_type];

  if (!BlockComponent) {
    return null;
  }

  if ((loggedInOnly && !hasSessionUser) || (anonymousOnly && hasSessionUser)) {
    return null;
  }

  const componentProps = { ...rest, className: _type };
  const margin = getMargin(props);
  const pad = getPadding(props);

  if (pad) {
    componentProps.pad = pad;
  }
  if (margin) {
    componentProps.margin = margin;
  }

  return <BlockComponent {...componentProps} />;
};

export const Block = memo(LegoBlock);

export const Blocks: React.FunctionComponent<any> = (props) => {
  const { items, children, before, after, lazyHydrate, Component = Box, ...rest } = props;

  const { source, blockByType } = useContext(LegoGlobalContext);

  return (
    <Component {...rest}>
      {before}
      {children || renderBlocks(items, source, blockByType, lazyHydrate)}
      {after}
    </Component>
  );
};

export type BuildingBlocksProps = BoxProps & { items: object[] };

function memoize(fn) {
  const cacheByType = {};

  return (arg, type, ...args) => {
    const cache = cacheByType[type] || (cacheByType[type] = new Map());
    const cachedValue = cache.get(arg);

    if (cachedValue) {
      return cachedValue;
    }

    const result = fn(arg, type, ...args);

    cache.set(arg, result);

    return result;
  };
}

export default Blocks;
