import React, { type LabelHTMLAttributes } from 'react';
import { cn } from '@superside/ui';
import { Box, type BoxProps } from '../Box';
import { Text } from '../Text';

export const Label: React.FC<
  BoxProps &
    LabelHTMLAttributes<HTMLLabelElement> & {
      invalid?: boolean;
    }
> = ({ invalid, ...props }) => (
  <Box as='label' {...(props as any)}>
    <Text
      size='xsmall'
      className={cn('m-0 font-bold uppercase tracking-[0.2px]', invalid && 'text-coral-500')}
      alternative
    >
      {props.children}
    </Text>
  </Box>
);
