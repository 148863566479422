import React from 'react';
import { type BoxProps } from '@konsus/superside-kit';
import { Blocks } from './blocks';

export type BuildingBlocksProps = BoxProps & { items: object[] };

export const BuildingBlocks: React.FunctionComponent<BuildingBlocksProps> = (props) => {
  return <Blocks align='start' {...props} lazyHydrate />;
};

export default BuildingBlocks;
