import validator from 'email-validator';
import { get as getCookie } from 'js-cookie';
import { type NextRouter } from 'adapter/next/router';
import { TRACKING_EVENT_NAMES, userEventsTracking } from '@konsus/analytics';
import { CHILI_PIPER_CONFIG } from './utils';
import type { ChiliPiperType } from './useChiliPiper';

declare global {
  interface Window {
    ChiliPiper: ChiliPiperType;
  }
}

export function extractEmailAddressFromQueryString(history: NextRouter) {
  const email = history.query.email;

  if (!email) {
    return null;
  }

  const emailString = decodeURIComponent(Array.isArray(email) ? email[0] : email);

  if (emailString && validator.validate(emailString)) {
    return emailString;
  }

  return null;
}

export function fetchExtraData() {
  return Promise.all([fetch('/api/user-ip'), fetch('/api/user-agent')])
    .then(([ipResponse, userAgentResponse]) =>
      Promise.all([ipResponse.json(), userAgentResponse.json()])
    )
    .then(([ip, userAgent]) => {
      return {
        ip,
        userAgent,
        fbp: getCookie('_fbp'),
        fbc: getCookie('_fbc')
      };
      // TODO: add exception handling
    });
}

export async function generateExtraFields(eventId: string, fbclid: string | string[] | undefined) {
  const { ip, userAgent, fbp, fbc } = await fetchExtraData();
  const fields = [{ name: 'event_id', value: eventId }];

  if (fbp) {
    fields.push({ name: 'fbp', value: fbp });
  }
  if (fbc) {
    fields.push({ name: 'fbc', value: fbc });
  }

  if (!fbc && fbclid) {
    fields.push({ name: 'fbc', value: `fb.1.${Date.now()}.${fbclid}` });
  }

  if (ip) {
    fields.push({ name: 'fb_ip_address', value: ip.data });
  }
  if (userAgent) {
    fields.push({ name: 'user_agent', value: userAgent.data });
  }

  return fields;
}

type SetChilliPiperLoaderType = (options: {
  enabled: boolean;
  name: string | null;
  company: string | null;
}) => void;

type ChiliPiperSubmissionOptions = {
  lead: Record<string, string>;
  routerName: string;
  track: (event: string, data: any) => void;
  eventId: string;
  setChiliPiperLoader: SetChilliPiperLoaderType;
};

export async function submitChiliPiperFormAndScheduleCall({
  lead,
  routerName,
  track,
  eventId,
  setChiliPiperLoader
}: ChiliPiperSubmissionOptions) {
  return new Promise((resolve, reject) => {
    setChiliPiperLoader({ enabled: true, name: lead.firstname, company: lead.company });

    const hideChiliPiperLoader = () =>
      setChiliPiperLoader({ enabled: false, name: null, company: null });

    let submitSuccess = false;

    const trackUserEvent = (eventName: string, extraProperties = {}) => {
      track(eventName, {
        user: {
          ...userEventsTracking
        },
        provider: 'chilipiper',
        ...extraProperties
      });
    };

    if (!window.ChiliPiper) {
      throw new Error(`ChiliPiper is not available`);
    }

    const payloadObject = {
      ...CHILI_PIPER_CONFIG.options,
      onSuccess: (e: unknown) => {
        submitSuccess = true;

        trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_SUBMISSION);
        window.removeEventListener('message', availabilityLoadedHandler, false);

        hideChiliPiperLoader();
        setTimeout(() => {
          resolve(e);
        }, 5000);
      },
      onError: (e: { message: string }) => {
        const eventName = e.message?.toLowerCase().replace(/\s/g, '-') || 'error';

        trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_FAILURE, { error: eventName });
        window.removeEventListener('message', availabilityLoadedHandler, false);
        hideChiliPiperLoader();
        reject(e);
      },
      onClose: (e: unknown) => {
        if (submitSuccess) {
          resolve(e);
        } else {
          trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_CLOSED);
          window.removeEventListener('message', availabilityLoadedHandler, false);
          hideChiliPiperLoader();
          reject(e);
        }
      },
      event: {
        eventId
      },
      lead
    };

    const availabilityLoadedHandler = (event: { data: { action: any } }) => {
      const action = event?.data?.action;

      if (action === 'availability-loaded') {
        hideChiliPiperLoader();
        trackUserEvent(TRACKING_EVENT_NAMES.CALL_REQUEST_START);
      }

      if (action === 'no-free-slots') {
        const popupElement = document.querySelector('.chilipiper-popup');

        if (popupElement) {
          popupElement.remove();
        }

        window.ChiliPiper.submit(CHILI_PIPER_CONFIG.domain, 'fallback_router', {
          ...payloadObject,
          map: true
        });
      }
    };

    window.addEventListener('message', availabilityLoadedHandler, false);

    window.ChiliPiper.submit(CHILI_PIPER_CONFIG.domain, routerName, payloadObject);
  });
}

export async function submitChiliPiperForm({
  lead,
  routerName,
  eventId
}: Omit<ChiliPiperSubmissionOptions, 'track' | 'setChiliPiperLoader'>) {
  const POST_URL = `https://api.chilipiper.com/marketing/${CHILI_PIPER_CONFIG.domain}`;

  const res = await fetch(POST_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      form: lead,
      options: {
        ...CHILI_PIPER_CONFIG.options,
        router: routerName
      },
      event: {
        eventId
      }
    })
  });

  if (res.ok) {
    return Promise.resolve();
  } else {
    const errorMessage = await res.json();

    // eslint-disable-next-line no-console
    console.error(errorMessage);

    return Promise.reject(new Error(`Sorry, something went wrong`));
  }
}

export const processAnswersForPayloads = ({ answers }: { answers: Record<string, string> }) => {
  const SUFFIX = '_chilipiper';
  const hubspotPayload = { ...answers };
  const chiliPiperPayload = { ...answers };

  Object.keys(answers).forEach((key) => {
    if (key.endsWith(SUFFIX)) {
      const newKey = key.slice(0, -SUFFIX.length);

      chiliPiperPayload[newKey] = answers[key];
      delete chiliPiperPayload[key];
      delete hubspotPayload[key];
    }
  });

  return { hubspotPayload, chiliPiperPayload };
};
