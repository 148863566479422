import React, { forwardRef } from 'react';
import { cn } from '@superside/ui';
import { Box, RelativeBox } from '../Box';
import { type BreakpointBasisBoxProps } from './BreakpointBasisBoxProps';
import { useFlexBasisProps } from './breakpointBasisStyles';

export type { BreakpointBasisBoxProps };

export const BreakpointBasisBox: React.FunctionComponent<BreakpointBasisBoxProps> = forwardRef(
  (props, ref) => {
    const { style, className } = useFlexBasisProps(props);

    return (
      <Box
        ref={ref}
        {...props}
        style={{
          ...props.style,
          ...style
        }}
        className={cn(props.className, className)}
      >
        {props.children}
      </Box>
    );
  }
);
BreakpointBasisBox.displayName = 'BreakpointBasisBox';

export const RelativeBreakpointBasisBox: React.FunctionComponent<BreakpointBasisBoxProps> =
  forwardRef((props, ref) => {
    const { style, className } = useFlexBasisProps(props);

    return (
      <RelativeBox
        ref={ref}
        {...props}
        style={{
          ...props.style,
          ...style
        }}
        className={cn(props.className, className)}
      >
        {props.children}
      </RelativeBox>
    );
  });
RelativeBreakpointBasisBox.displayName = 'RelativeBreakpointBasisBox';

export default BreakpointBasisBox;
