export {
  buttonWrap,
  checkboxStyle,
  formStyle,
  fullName,
  fullNameBox,
  minimalCostNoteStyle,
  styledForm,
  styledFormField,
  styledText,
  submitButton,
  submitButtonWrapper
} from './styles';

export const createFieldName = (id: string) => `field_${id}`;

export const isDev = process.env.ENVIRONMENT === 'DEVELOPMENT';

export const CHILI_PIPER_CONFIG = {
  domain: 'superside',
  router: isDev ? 'test_router' : 'inbound-router',
  options: {
    title: 'Thanks! What time works best for a quick call?',
    map: true
  }
};

export const FORM_CONFIG = {
  formId: isDev ? '6a750863-0fef-465d-b46e-4204da6d5d16' : '862e6326-9c70-4282-90e7-d49ec92e2f0e',
  redirectLink: '/call-confirmed',
  formName: 'Empty'
};

export const DEFAULT_HUBSPOT_FORM_ID = isDev
  ? '6a750863-0fef-465d-b46e-4204da6d5d16'
  : '862e6326-9c70-4282-90e7-d49ec92e2f0e';

export const hubSpotApiPoint = 'https://api.hsforms.com/submissions/v3/integration/submit';
export const portalId = '6380455';
