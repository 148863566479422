import { useMemo } from 'react';
import { get as getCookie } from 'js-cookie';

export const prepareHubspotFormData = (fields) => ({
  submittedAt: Date.now(),
  fields,
  context: {
    hutk: getCookie('hubspotutk'),
    pageUri: window.location.href
  },
  legalConsentOptions: {
    consent: {
      consentToProcess: true,
      text: 'I agree to receive marketing emails from Superside.'
    }
  },
  skipValidation: true
});

export const getErrorMessage = (field): string | null =>
  field.meta.touched && field.meta.error ? field.meta.error : null;

export function useEventId() {
  return useMemo(() => `${new Date().getTime()}_${Math.random().toString().split('.')[1]}`, []);
}
